<template>
    <div style="height: 100%;position:relative;">

        <!--搜索区域-->
        <div class="header">
            <!--<div style="">-->
                <!--房间名称：-->
                <!--<el-input v-model="roomName" size="medium" placeholder="请输入房间名称" @keyup.enter.native="GetRoomList" @submit.native.prevent></el-input>-->
            <!--</div>-->
            <div style="">
                房间号：
                <el-input v-model="roomNumber" size="medium" placeholder="请输入房间号" @keyup.enter.native="GetRoomList" @submit.native.prevent></el-input>
            </div>
            <!--<div style="">-->
                <!--楼层：-->
                <!--<el-input v-model="floorNumber" size="medium" placeholder="请输入楼层" @keyup.enter.native="GetRoomList" @submit.native.prevent></el-input>-->
            <!--</div>-->
            <div style="">
                房型：
                <el-select clearable v-model="templateId" placeholder="请选择" @change="GetRoomList">
                    <el-option v-for="item in roomTypeSel" :key="item.templateId" :label="item.templateName" :value="item.templateId"></el-option>
                </el-select>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="GetRoomList()">搜索</el-button>
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addRoomfun">新增</el-button>
            <el-button type="primary" icon="el-icon-delete" @click="handleDelete">批量删除</el-button>
        </div>
        <div class="wrapper">
            <el-table :data="tableData" ref="table" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange" @row-click="handleHighlightchangeTable">
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <!--<el-table-column prop="roomName" label="房间名称"></el-table-column>-->
                <el-table-column prop="roomNumber" label="房间号"></el-table-column>
                <el-table-column prop="templateName" label="房型"></el-table-column>
                <!--<el-table-column prop="floorNumber" label="楼层号" ></el-table-column>-->
                <el-table-column prop="roomType" label="房间属性">
                    <template scope="scope">
                        <span v-if="scope.row.roomType===0">样板房间</span>
                        <span v-else-if="scope.row.roomType===1">正式房间</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template scope="scope">
                        <span v-if="scope.row.status===0">未配置</span>
                        <span v-else-if="scope.row.status===1">配置中</span>
                        <span v-else-if="scope.row.status===2">配置完成</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" width="200"></el-table-column>
                <el-table-column prop="address" label="操作" width="200">
                    <template slot-scope="scope">
                        <div class="operate">
                            <span @click="handleEdit(scope)" style="cursor : pointer;">编辑</span>
                            <span @click="delOneRoom(scope)" style="cursor : pointer;">删除</span>
                            <span @click="openDetail(scope)" style="cursor : pointer;">详情</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!--分页-->
            <Mypage :total="total" :pageSize="limit" @changePage="changePage"/>
        </div>



        <!--增加编辑对话框-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false" :before-close="resetForm">
            <el-form :model="roomForm" status-icon :rules="rules" ref="roomForm" class="demo-ruleForm">
                <!--<el-form-item label="房间名称" prop="roomName">-->
                    <!--<el-input type="text" v-model="roomForm.roomName" autocomplete="off"></el-input>-->
                <!--</el-form-item>-->
                <div v-if="title=='新增'">
                    <el-form-item label="批量创建方式">
                        <el-radio v-model="createType" :label="0" @input="changeRadio">手动创建</el-radio>
                        <el-radio v-model="createType" :label="1" @input="changeRadio">连续创建</el-radio>
                    </el-form-item>
                    <el-form-item label="房间号" prop="roomNumber" v-if="createType==0">
                        <el-input type="textarea" autosize v-model="roomForm.roomNumber" autocomplete="off" @input="valueChange"></el-input>
                        <div style="color:red">{{refText}}</div>
                    </el-form-item>
                    <div style="overflow:hidden;" v-if="createType==1">
                        <el-form-item label="房间号" prop="startNum" style="float:left;">
                            <el-input type="text" v-model="roomForm.startNum" autocomplete="off" @input="valueChange" onkeyup="value=value.replace(/[^\d]/g, '')"></el-input> -
                            <div style="color:red;clear:both;">{{refText}}</div>
                        </el-form-item>
                        <el-form-item prop="endNum" style="float:left;">
                            <el-input type="text" v-model="roomForm.endNum" autocomplete="off" @input="valueChange" onkeyup="value=value.replace(/[^\d]/g, '')"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div v-if="title=='编辑'">
                    <el-form-item label="房间号" prop="roomNumber">
                        <el-input type="textarea" autosize v-model="roomForm.roomNumber" autocomplete="off" @input="valueChange"></el-input>
                        <div style="color:red">{{refText}}</div>
                    </el-form-item>
                </div>

                <!--<el-form-item label="房间号" prop="roomNumber" v-else>-->
                    <!--<el-input type="text" v-model="roomForm.roomNumber" autocomplete="off"></el-input>-->
                <!--</el-form-item>-->
                <el-form-item label="房型" prop="templateId">
                    <el-select v-model="roomForm.templateId" placeholder="请选择">
                        <el-option
                                v-for="item in templateList"
                                :key="item.templateId"
                                :label="item.templateName"
                                :value="item.templateId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!--<el-form-item label="楼层号" prop="floorNumber">-->
                    <!--<el-input type="text" v-model="roomForm.floorNumber" autocomplete="off"></el-input>-->
                <!--</el-form-item>-->
                <el-form-item label="施工人员" prop="installerUserId">
                    <el-select v-model="roomForm.installerUserId" placeholder="请选择">
                        <el-option v-for="item in installerList" :key="item.userId" :label="item.nickName" :value="item.userId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="房间描述" prop="roomDesc">
                    <el-input type="textarea" autosize v-model="roomForm.roomDesc" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="房间属性" prop="roomType">
                    <el-radio v-model="roomForm.roomType" :label="1">正式房间</el-radio>
                    <el-radio v-model="roomForm.roomType" :label="0">样板房间</el-radio>
                </el-form-item>
                <el-form-item style="text-align: right">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitRoomMsg('roomForm')">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!--详情弹框-->
        <el-dialog title="房间信息" :visible.sync="detailVisible" width="50%" center  :before-close="handleClose" :close-on-click-modal="false">
            <div style="overflow:hidden;">
                <!--<div class="detailRoomCont"><span style="color:#409EFF">房间名称: </span>{{detailData.roomName}}</div>-->
                <div class="detailRoomCont"><span style="color:#409EFF">房间号: </span>{{detailData.roomNumber}}</div>
                <div class="detailRoomCont"><span style="color:#409EFF">房型: </span>{{detailData.templateName}}</div>
                <div class="detailRoomCont"><span style="color:#409EFF">房间状态: </span>
                        <span v-if="detailData.status===0">未配置</span>
                        <span v-else-if="detailData.status===1">配置中</span>
                        <span v-else-if="detailData.status===2">配置完成</span>
                </div>
                <div class="detailRoomCont"><span style="color:#409EFF">房间属性: </span>
                    <span v-if="detailData.roomType===0">样板房间</span>
                    <span v-else-if="detailData.roomType===1">正式房间</span>
                </div>
                <div class="detailRoomCont"><span style="color:#409EFF">施工人员: </span>{{detailData.installerUserName}}</div>
                <div class="detailRoomCont"><span style="color:#409EFF">创建时间: </span>{{detailData.createTime}}</div>
                <div style="padding:0 20px;margin:10px 0;float:left;width:100%;"><span style="color:#409EFF">房间描述: </span>{{detailData.roomDesc}}</div>
            </div>
            <div style="margin:10px 20px;">
                <div style="margin-bottom:10px;"><span style="color:#409EFF">房间设备：</span></div>
                <div style="overflow:hidden;">
                    <div v-for="(item,index) in deviceList" v-bind:key="index" style="float:left;width:31%;margin-right:1%;margin-bottom:5px;">
                        {{item.deviceRemark}}（<span v-if="item.deviceStatus==0" style="color:red">离线</span><span v-else-if="item.deviceStatus==1" style="color:green">在线</span><span v-else-if="item.deviceStatus==9" style="color:gray">未安装</span>）
                    </div>
                </div>
            </div>
            <!--<div style="margin:10px 20px;">-->
                <!--<div style="margin-bottom:10px;"><span style="color:#409EFF">房间二维码：</span><a style="color:#276EF1;cursor:pointer" @click="handleDownloadQrIMg(detailData.rqImageBase64)">保存二维码</a></div>-->
                <!--<img style="width:200px" :src="'data:image/png;base64,'+detailData.rqImageBase64" alt="">-->

            <!--</div>-->
        </el-dialog>
    </div>
</template>
<script>
    import Mypage from '../../components/MyPagination.vue'
    import {roomList,getTempList,getInstallerList,addRoom,RoomDetail,editRoom,delRoom,RoomDevice} from './manage'
    export default {
        components: {
            Mypage
        },
        name: "manage",
        // props: ['hotelId'],
        data() {
            return {
                hotelId:this.$cookies.get("hotelId"),
                dialogVisible: false, //打开新增模态框
                detailVisible:false,//详情模态框
                loading:true,
                title:'新增',
                floorNumber: '', //楼层号
                roomNumber: '',  //房间号
                templateId: '', //房型
                roomTypeSel:[],
                roomName: '',
                total:10,
                limit: 10,
                page: 1,
                tableData: [],
                templateList:[],//房型列表
                installerList:[],//施工人员列表
                detailData:{},// 房间详情
                createType:0, //批量创建类型   0----手输   1----连续
                refText:'',   //批量创建房间提示内容
                roomForm: {
                    // roomName: '', //房间名称
                    roomNumber: '', //房间号
                    startNum:'', //起始房间号
                    endNum:'',  //结尾房间号
                    templateId: '', //房间类型,
                    // floorNumber: '', //楼层号
                    installerUserId:'',// 施工人员
                    roomDesc:'',//描述
                    roomType:1,//房间属性，1=正式，0=样板
                },
                rules: {
                    // roomName: [
                    //     {required: true, message: "请输入房间名称", trigger: "blur",},
                    // ],
                    roomNumber: [
                        {required: true, message: "请输入房间号", trigger: "blur",},
                    ],
                    templateId: [
                        {required: true, message: "请选择房间类型", trigger: "blur"},
                    ],
                    // floorNumber: [
                    //     {required: true, message: '请输入楼层号', trigger: 'blur'},
                    // ],
                    installerUserId: [
                        {required: true, message: '请选择施工人员', trigger: 'blur'},
                    ],
                    roomType: [
                        {required: true, message: '请选择房间属性', trigger: 'blur'},
                    ],
                },
                deviceList:[],//房间设备列表
                multipleSelection: [],  //批量删除
            }
        },
        methods: {
            //表单验证重置
            resetForm(){
                this.dialogVisible=false
                this.$refs.roomForm.resetFields()
            },
            //    查询房间列表
            GetRoomList() {
                console.log('hote3ls',this.hotelId)
                roomList(this.hotelId, this.floorNumber, this.roomName, this.roomNumber,this.templateId, this.limit, this.page,this.$cookies.get("access_token")).then((result) => {
                    if(result.data.data!='请重新登录') {
                        if (result["data"].code == 0) {
                            this.tableData = result["data"]["data"]["records"]
                            this.total = parseInt(result["data"]["data"]["total"])
                        } else {
                            this.$message({
                                message: result["data"].message,
                                type: 'error'
                            });
                        }
                    }
                    this.loading=false
                })
            },
            //    查询房型列表
            getRoomType() {
                console.log('hote3ls',this.hotelId)
                //查询房型列表
                getTempList(this.hotelId,this.$cookies.get("access_token")).then((result)=>{
                    if(result.data.data!='请重新登录') {
                        if (result["data"].code == 0) {
                            this.roomTypeSel = result["data"].data
                        }
                    }
                }).catch(()=>{
                    this.$message({
                        message: '请求失败！',
                        type: 'success'
                    });
                })
            },
            addRoomfun(){
                console.log('instalist',this.installerList)
                if(this.installerList.length==0){
                    this.$message({
                        message: '请先添加施工人员！',
                        type: 'error'
                    });
                }else {
                    this.dialogVisible = true;
                    this.title='新增'
                    this.roomForm= {
                        // roomName: '', //房间名称
                        roomNumber: '', //房间号
                        startNum:'', //起始房间号
                        endNum:'',  //结尾房间号
                        templateId: '', //房间类型,
                        // floorNumber: '', //楼层号
                        installerUserId:'',// 施工人员
                        roomDesc:'',//描述
                        roomType:1,//房间属性，1=正式，0=样板
                    }
                }

                // this.getListFun()
            },

            //切换批量创建方式
            changeRadio(value){
              console.log('value',value)

                if(value==0){
                    this.valueChange(this.roomForm.roomNumber)
                    this.rules={
                        roomNumber: [
                            {required: true, message: "请输入房间号", trigger: "blur",},
                        ],
                        templateId: [
                            {required: true, message: "请选择房间类型", trigger: "blur"},
                        ],
                        installerUserId: [
                            {required: true, message: '请选择施工人员', trigger: 'blur'},
                        ],
                        roomType: [
                            {required: true, message: '请选择房间属性', trigger: 'blur'},
                        ],
                    }
                }else {
                    this.roomForm.startNum=''
                    this.roomForm.endNum=''
                    this.valueChange(this.roomForm.startNum)
                    this.rules={
                        roomNumber: [
                            {required: false, message: "请输入房间号", trigger: "blur",},
                        ],
                        startNum: [
                            {required: true, message: "请输入起始房间号", trigger: "blur"},
                        ],
                        endNum: [
                            {required: true, message: "请输入结尾房间号", trigger: "blur"},
                        ],
                        templateId: [
                            {required: true, message: "请选择房间类型", trigger: "blur"},
                        ],
                        installerUserId: [
                            {required: true, message: '请选择施工人员', trigger: 'blur'},
                        ],
                        roomType: [
                            {required: true, message: '请选择房间属性', trigger: 'blur'},
                        ],
                    }
                }
            },
            //获取当前输入的input内容
            valueChange(value){
                if(this.createType==0){
                    value=value.replace(/[^\d,，]/g,'').replace(/，/,',').replace(/,,/,',')
                    this.roomForm.roomNumber=value
                }
                console.log('inputvalue',value)
                console.log('roomNumber',this.roomForm.roomNumber)
                this.refText=''
                var arr=value.split(',')
                for(var i=0;i<arr.length;i++){
                    if(arr[i]>=10000){
                        console.log('太大啦')
                        this.refText='*房间号不能大于5位数'
                    }
                }
                if(this.createType==0){
                    if(arr.length>20){
                        this.refText='*一次最多创建20个房间'
                    }
                }else {
                    if(this.roomForm.startNum!=''&&this.roomForm.endNum!=''){
                        if(this.roomForm.endNum-this.roomForm.startNum>20||this.roomForm.startNum-this.roomForm.endNum>20){
                            this.refText='*一次最多创建20个房间'
                        }
                    }
                }

            },
            //    编辑
            handleEdit(scope) {
                this.dialogVisible = true
                this.title='编辑'
                // this.getListFun()
                RoomDetail(scope.row.roomId,this.$cookies.get("access_token")).then((result)=>{
                    if (result["data"].code == 0) {
                        this.roomForm=result["data"]["data"]
                    }
                })
                this.roomId=scope.row.roomId
            },
            //查询新增编辑模态框需要的房型列表及施工人员列表
            getListFun(){
                //查询房型列表
                getTempList(this.hotelId,this.$cookies.get("access_token")).then((result)=>{
                    if (result["data"].code == 0) {
                        this.templateList=result["data"].data
                    }
                }).catch(()=>{
                    this.$message({
                        message: '请求失败！',
                        type: 'error'
                    });
                })
                //    查询施工人员列表
                getInstallerList(this.hotelId,this.$cookies.get("access_token")).then((result)=>{
                    if (result["data"].code == 0) {
                        this.installerList=result["data"].data
                    }
                }).catch(()=>{
                    this.$message({
                        message: '请求失败！',
                        type: 'error'
                    });
                })
            },
            //    删除
            //单独删除房间
            delOneRoom(scope){
                this.multipleSelection[0]=scope.row.roomId
                this.handleDelete()
            },
            //多选房间删除
            handleSelectionChange(val) {
                this.multipleSelection=[]
                for(var i=0;i<val.length;i++){
                    this.multipleSelection.push(val[i].roomId)
                }
                // this.multipleSelection = val;
                console.log(' multipleSelection',this.multipleSelection)
            },
            handleHighlightchangeTable(row) {
                if (!row.disabled) {
                    this.$refs.table.toggleRowSelection(row)
                }
            },
            handleDelete() {
                if(this.multipleSelection.length===0){
                    this.$message({
                        message: '请选择要删除的房间',
                        type: 'error'
                    });
                }else {
                    this.$confirm('确认删除该房间吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.loading=true
                        delRoom(this.multipleSelection,this.$cookies.get("access_token")).then((result)=>{
                            if (result["data"].code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功!'
                                });
                                this.multipleSelection=[]
                                this.GetRoomList()
                            }else {
                                this.$message({
                                    message: result["data"].message,
                                    type: 'error'
                                });
                                this.multipleSelection=[]
                                this.loading=false
                            }
                        })

                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                        this.multipleSelection=[]
                    });
                }
            },
            // 提交房间模态框数据
            submitRoomMsg(formName) {
                console.log('formname',this.roomForm)
                this.loading=true
                if(this.title=='新增'){
                    if(this.createType==0){
                        this.roomForm.startNum=''
                        this.roomForm.endNum=''
                    }else {
                        this.roomForm.roomNumber=''
                    }
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            var params={
                                hotelId:this.hotelId,
                                // floorNumber: this.roomForm.floorNumber,
                                installerUserId: this.roomForm.installerUserId,
                                roomDesc: this.roomForm.roomDesc,
                                // roomName: this.roomForm.roomName,
                                roomNumber: this.roomForm.roomNumber,
                                startNum:this.roomForm.startNum, //起始房间号
                                endNum:this.roomForm.endNum,  //结尾房间号
                                roomType: this.roomForm.roomType,
                                templateId: this.roomForm.templateId,
                            }
                            addRoom(params,this.$cookies.get("access_token")).then((result)=>{
                                if(result.data.code==0){
                                    this.$message({
                                        message: '添加成功！',
                                        type: 'success'
                                    });
                                    this.dialogVisible = false
                                    this.GetRoomList();
                                }else {
                                    this.$message({
                                        message: result.data.message,
                                        type: 'error'
                                    });
                                    this.loading=false
                                }

                            }).catch(()=>{
                                this.$message({
                                    message: '请求失败！',
                                    type: 'success'
                                });
                                this.dialogVisible = false
                                this.loading=false
                            })
                        }
                    })
                }else if(this.title=='编辑'){
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                                var params={
                                    roomId:this.roomId, //房间ID
                                    // floorNumber: this.roomForm.floorNumber,
                                    installerUserId: this.roomForm.installerUserId,
                                    roomDesc: this.roomForm.roomDesc,
                                    // roomName: this.roomForm.roomName,
                                    roomNumber: this.roomForm.roomNumber,
                                    startNum:this.roomForm.startNum, //起始房间号
                                    endNum:this.roomForm.endNum,  //结尾房间号
                                    roomType: this.roomForm.roomType,
                                    templateId: this.roomForm.templateId,
                                }
                                editRoom(params,this.$cookies.get("access_token")).then((result)=>{
                                    if(result["data"].code==0){
                                        this.$message({
                                            message: '修改成功！',
                                            type: 'success'
                                        });

                                        this.GetRoomList();
                                    }else {
                                        this.$message({
                                            message: result["data"].message,
                                            type: 'error'
                                        });
                                        this.loading=false
                                    }
                                    this.dialogVisible = false
                                }).catch(()=>{
                                    this.$message({
                                        message: '请求失败！',
                                        type: 'error'
                                    });
                                    this.dialogVisible = false
                                    this.loading=false
                                })
                        }
                    })
                }

            },

            //打开详情弹框
            openDetail(scope){
                this.detailVisible = true
                this.roomId=scope.row.roomId
                //房间详情
                RoomDetail(scope.row.roomId,this.$cookies.get("access_token")).then((result)=>{
                    if (result["data"].code == 0) {
                        this.detailData=result["data"]["data"]
                        // this.handleDownloadQrIMg(this.detailData.rqImageBase64)
                    }

                })
            //    房间设备
                RoomDevice(this.roomId,this.$cookies.get("access_token")).then((result)=>{
                    if (result["data"].code == 0) {
                        this.deviceList=result["data"]["data"]
                    }
                })

            },
            //下载小程序二维码
            handleDownloadQrIMg(qrBase64) {
                // 这里是获取到的图片base64编码,这里只是个例子哈，要自行编码图片替换这里才能测试看到效果
                const imgUrl = `data:image/png;base64,${qrBase64}`
                // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
                if (window.navigator.msSaveOrOpenBlob) {
                    const bstr = atob(imgUrl.split(',')[1])
                    let n = bstr.length
                    const u8arr = new Uint8Array(n)
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n)
                    }
                    const blob = new Blob([u8arr])
                    window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png')
                } else {
                    // 这里就按照chrome等新版浏览器来处理
                    const a = document.createElement('a')
                    a.href = imgUrl
                    a.setAttribute('download', 'chart-download')
                    a.click()
                }
            },
            handleClose(done) {
                        done();
            },
            //    分页
            changePage(num){
                this.page=num;
                this.GetRoomList()
            },


        },
        created() {  /*生命周期函数*/
            this.GetRoomList();
            this.getRoomType()
            this.getListFun()
            console.log('hotelid',this.hotelId)
        }
    }
</script>

<style scoped>
    .header {
        display: flex;
    }

    .el-input {
        width: 150px;
        margin: 0 10px;
    }

    .el-button {
        padding: 10px 20px;
    }
    .el-select {
        margin:0 10px;
    }
    ::v-deep .el-form-item__content {
        display:inline-block;
        /*width:70%;*/
    }
    ::v-deep .el-textarea__inner {
        height:36px !important;
    }
    .el-textarea {
        margin:0 10px;
    }

    .wrapper {
        margin-top: 20px;
        text-align: center;
    }

    .operate span {
        color: #276EF1;
        margin: 0 5px;
    }

    ::v-deep .el-table th, ::v-deep .el-table td {
        text-align: center !important;
    }

    ::v-deep .el-form-item__label {
        width: 100px
    }
    ::v-deep .el-dialog__header {
        border-bottom:1px solid #ddd;
    }

    .detailRoomCont {
        float:left;
        width:40%;
        padding:0 20px;
        margin:10px 0;
    }
    ::v-deep .el-input__inner {
        height:36px;
    }
</style>
